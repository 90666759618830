import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { MainInput, centered_flex_box, main_button } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { Image } from 'antd';
import QR from "../../app/components/QR-Code Egypt Career Event.png"
import { doc, getDoc, getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../app/config"
import { useState } from 'react';
import { useEffect } from 'react';
import QRCode from 'react-qr-code';
export const InternRoles = ({ }) => {

    const navigate = useNavigate()
    const { graduate_status, id } = useParams()
    const [isLoading, setIsLoading] = useState(false);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        getMajorAndRoles()
    }, [])

    const getMajorAndRoles = async () => {
        setIsLoading(true)
        const q = query(collection(db, "role"), where("graduate_status", "==", "Undergraduate"));
        const querySnapshot = await getDocs(q);
        const data = []
        querySnapshot.forEach((doc) => {
            data.push({id: doc.id, ...doc.data()});
        });
        console.log(data)
        setRoles(data);
        setIsLoading(false)
    }
    
    return (
        <div>
            <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100vw", padding: 2, marginBottom: 5 }}>
                <Typography fontWeight="bold" variant="h2" sx={{ color: "var(--secColor)" }}>Internships</Typography>
            </Box>
            {!isLoading && <Box sx={{ ...centered_flex_box, minHeight: "50vh", flexDirection: "column" }}>
                <Typography fontWeight="bold" variant="h4" sx={{ color: "var(--secColor)" }}>{graduate_status}</Typography>
                <Grid container spacing={3} sx={{ ...centered_flex_box, width: "100vw", marginBottom: 5 }}>
                    {roles?.map((role, idx) => {return <Grid key={idx} item xs={12} sx={{ ...centered_flex_box, flexDirection: "column", justifyContent: "start" }}>
                        <Grid item xs={12} sx={{ ...centered_flex_box }}>
                            <Button onClick={() => navigate(`/Scan/${role.id}/Internship`)} key={1} sx={{ ...main_button, width: "80vw", fontSize: 20 }}>
                                {role.title}
                            </Button>
                        </Grid>
                    </Grid>})}
                </Grid>
            </Box>}
            {isLoading && <Box sx={centered_flex_box}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>}
        </div>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InternRoles);