import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Drawer, Grid, Link, Tooltip } from "@mui/material";
import { centered_flex_box, left_flex_box, right_flex_box } from "./Styles";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Logo from "../components/Henkel_Logo_White.png"
import { Image } from "antd";

function Footer({ }) {
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        sx={{
          bgcolor: "var(--secColor)",
          color: "var(--appBg)",
          width: "100vw",
          paddingX: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ ...left_flex_box, flexDirection: "column" }}>
            <Typography fontSize={20} fontWeight="bold">
              © 2025 Integra All Rights Reserved
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ ...right_flex_box, flexDirection: "column", padding: 2 }} >
            <Image height={50} width={89.33} preview={false} src={"https://integra-apps.s3.eu-west-3.amazonaws.com/henkel-career-fair/Henkel_Logo_White.png"} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state?.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
