import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { MainInput, centered_flex_box, main_button, sec_button } from '../app/components/Styles';
import { isDesktop, isIPad13, isMobile, isTablet } from 'react-device-detect';
import { Image, QRCode } from 'antd';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { doc, getDoc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../app/config";
import { People } from '@mui/icons-material';
import { useState } from 'react';

export const MeetTheTeam = ({ }) => {

    const [isRedirecting, setIsRedirecting] = useState(false)

    const logView = async (link, search_term) => {
        setIsRedirecting(true);

        const viewRef = await addDoc(collection(db, "views"), {
            search_term,
            scanned_at: serverTimestamp(),
        });

        setTimeout(() => {
            window.location.href = link 
        }, 500);
    }

    return (
        <div>
            {!isRedirecting && <Box>
                <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100vw", padding: 2, marginBottom: 1 }}>
                    <Typography textAlign="center" fontWeight="bold" variant="h3" sx={{ color: "var(--secColor)" }}>Recruitment Team</Typography>
                </Box>
                <Grid container spacing={5} sx={{ ...centered_flex_box, marginBottom: 15, padding: 2 }}>
                    <Grid item sx={{...centered_flex_box, flexDirection: "column"}} xs={isMobile ? 6 : 4}>
                        <Avatar sx={{width: 100, height: 100, marginBottom: 2}} src="https://media.licdn.com/dms/image/v2/D4D03AQHvVEAjqW2P2g/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1716317868356?e=1736985600&v=beta&t=R-FlM9biBfBwcegQoC39migqMdAFv0DJdAPLfxCs_e8"/>
                        {(isTablet || isDesktop) && <Typography>Nancy Ihab</Typography>}
                        {isMobile && <Button fullWidth sx={{...main_button}} onClick={() => logView("https://www.linkedin.com/in/nancykamel/", "Nancy Ihab")}>Nancy Ihab</Button>}
                        {(isTablet || isDesktop) && <QRCode icon="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png" value="https://www.linkedin.com/in/nancykamel/"/>}
                    </Grid>
                    <Grid item sx={{...centered_flex_box, flexDirection: "column"}} xs={isMobile ? 6 : 4}>
                        <Avatar sx={{width: 100, height: 100, marginBottom: 2}} src="https://media.licdn.com/dms/image/v2/D4D03AQHoDBcqxnrtYA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1718299666768?e=1736985600&v=beta&t=uR2Owje7SlbKtPCB41-QJuhu2M6r5uNUp9SrO6NmZ8A"/>
                        {(isTablet || isDesktop) && <Typography>Dareen Tantawy</Typography>}
                        {isMobile && <Button fullWidth sx={{...main_button}} onClick={() => logView("https://www.linkedin.com/in/dareen-tantawy-/", "Dareen Tantawy")}>Dareen Tantawy</Button>}
                        {(isTablet || isDesktop) && <QRCode icon="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png" value="https://www.linkedin.com/in/dareen-tantawy-/"/>}
                    </Grid>
                    <Grid item sx={{...centered_flex_box, flexDirection: "column"}} xs={isMobile ? 6 : 4}>
                        <Avatar sx={{width: 100, height: 100, marginBottom: 2}} src="https://firebasestorage.googleapis.com/v0/b/henkel-career-fairs.appspot.com/o/images%2FWhatsApp%20Image%202024-11-15%20at%2021.55.22.jpeg?alt=media&token=ca71eb2c-abbc-4449-8a0c-48f5f61125bd"/>
                        {(isTablet || isDesktop) && <Typography>Salma ElHossainy</Typography>}
                        {isMobile && <Button fullWidth sx={{...main_button}} onClick={() => logView("https://www.linkedin.com/in/salma-elhossainy-241083155/", "Salma ElHossainy")}>Salma ElHossainy</Button>}
                        {(isTablet || isDesktop) && <QRCode icon="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png" value="https://www.linkedin.com/in/salma-elhossainy-241083155/"/>}
                    </Grid>
                    <Grid item sx={{...centered_flex_box, flexDirection: "column"}} xs={isMobile ? 6 : 4}>
                    <Avatar sx={{width: 100, height: 100, marginBottom: 2}} src="https://media.licdn.com/dms/image/v2/D4D03AQGdkglsv-HPMQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1718312924332?e=1736985600&v=beta&t=Kg0BmPTrgIjvWH0FMLtFjf9U_BxuanUq-3JmQWEmt7Q"/>
                        {(isTablet || isDesktop) && <Typography>Yasmine Elemary</Typography>}
                        {isMobile && <Button fullWidth sx={{...main_button}} onClick={() => logView("https://www.linkedin.com/in/yasmine-elemary-15014980/", "Yasmine Elemary")}>Yasmine Elemary</Button>}
                        {(isTablet || isDesktop) && <QRCode icon="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png" value="https://www.linkedin.com/in/yasmine-elemary-15014980/"/>}
                    </Grid>
                    <Grid item sx={{...centered_flex_box, flexDirection: "column"}} xs={isMobile ? 6 : 4}>
                        <Avatar sx={{width: 100, height: 100, marginBottom: 2}} src="https://media.licdn.com/dms/image/v2/D4D03AQEwoek8VznGCw/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1724235996024?e=1736985600&v=beta&t=t2obQT2FxdPq6fckqB7Bs410k8JK_4F7P_GY-3vaFgU"/>
                        {(isTablet || isDesktop) && <Typography>Michael Youssef</Typography>}
                        {isMobile && <Button fullWidth sx={{...main_button}} onClick={() => logView("https://www.linkedin.com/in/michael-youssef-abaskhairon-73335522a/", "Michael Youssef")}>Michael Youssef</Button>}
                        {(isTablet || isDesktop) && <QRCode icon="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png" value="https://www.linkedin.com/in/michael-youssef-abaskhairon-73335522a/"/>}
                    </Grid>
                </Grid>
            </Box>}
            {isRedirecting && <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100vw", height: "80vh", padding: 2 }}>
                <Image height={100} width={178.65} preview={false} src={"https://integra-apps.s3.eu-west-3.amazonaws.com/henkel-career-fair/Henkel_Logo.png"} />
                <Typography fontWeight="bold" variant="h3" sx={{ color: "var(--secColor)" }}>Redirecting...</Typography>
                <Box sx={centered_flex_box}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>
            </Box>}
        </div>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MeetTheTeam);