import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const InactivityTimeoutWrapper = ({ children }) => {
  const timeoutRef = useRef({ current: null }); // Use an object as the initial value
  const navigate = useNavigate();

  const resetTimeout = () => {
    if (timeoutRef.current.current) {
      clearTimeout(timeoutRef.current.current);
    }

    // Set a new timeout
    timeoutRef.current.current = setTimeout(() => {
      // Timeout has occurred, navigate back to "/"
      navigate('/');
    }, 300000); // 5 minutes in milliseconds
  };

  const handleUserActivity = () => {
    resetTimeout();
  };

  useEffect(() => {
    // Set up initial timeout
    resetTimeout();

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Clean up event listeners on component unmount
    return () => {
      if (timeoutRef.current.current) {
        clearTimeout(timeoutRef.current.current);
      }
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, []); // No dependencies

  return <>{children}</>;
};

export default InactivityTimeoutWrapper;
