import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { MainInput, centered_flex_box, main_button } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import QRCode from 'react-qr-code';
import { useState } from 'react';
import { TextDecrease, TextIncrease } from '@mui/icons-material';
import { doc, getDoc, getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../app/config"
import { useEffect } from 'react';
import Markdown from 'markdown-to-jsx';

export const Role = ({ }) => {

    const [fontSize, setFontSize] = useState(18)
    const { major, id } = useParams()
    const [isLoading, setIsLoading] = useState(false);
    const [role, setRole] = useState([]);

    useEffect(() => {
        getRole()
    }, [])

    const getRole = async () => {
        setIsLoading(true)
        const docRef = doc(db, "role", id);
        const docSnap = await getDoc(docRef);
        setRole({id: docSnap.id, ...docSnap.data()})
        setIsLoading(false)
    }

    const increaseFontSize = () => {
        setFontSize((prevSize) => prevSize + 2);
    };

    const decreaseFontSize = () => {
        setFontSize((prevSize) => Math.max(12, prevSize - 2));
    };

    return (
        <div>
            <Box sx={{ ...centered_flex_box, flexDirection: "column", marginLeft: 20, marginRight: 20, padding: 2 }}>
                <Typography textAlign={"center"} fontWeight="bold" variant="h2" sx={{ color: "var(--secColor)" }}>{role?.title}</Typography>
                <Typography variant="h3" sx={{ color: "var(--secColor)" }}>{role?.location}</Typography>
            </Box>
            <Grid container minHeight={"80vh"}>
                <Grid item xs={8} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: 5, fontSize }}>
                    <Markdown>{role?.description || ""}</Markdown>
                    <Typography variant="p" fontWeight="bold" textAlign="justify" fontSize={fontSize} gutterBottom>
                        Experience: {role?.maximum_experience ? `${role?.minimum_experience} - ${role?.maximum_experience}` : `${role?.minimum_experience}+`} years
                    </Typography>
                    <Typography variant="p" textAlign="justify" fontSize={fontSize} gutterBottom>
                        Henkel is an equal opportunity employer. We look for a diverse team of individuals who possess different backgrounds, experiences, and mindsets.
                    </Typography>
                    <Box sx={{ ...centered_flex_box }}>
                        <IconButton sx={{ margin: 2 }} onClick={increaseFontSize}>
                            <TextIncrease sx={{ color: "var(--secColor)", width: "2vw", height: "2vw" }} />
                        </IconButton>
                        <IconButton sx={{ margin: 2 }} onClick={decreaseFontSize}>
                            <TextDecrease sx={{ color: "var(--secColor)", width: "2vw", height: "2vw" }} />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={4} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: 5 }}>
                    <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                        <QRCode
                            style={{ height: "auto", maxWidth: "70%", width: "70%", marginBottom: 2 }}
                            fgColor="var(--secColor)"
                            bgColor="var(--appBg)"
                            value={`${process.env.REACT_APP_BASE}/Scan/${id}/${major}`}
                        />
                        <Typography variant='p' marginTop={5} fontSize={25}>Scan to apply</Typography>
                    </Box>
                </Grid>
            </Grid>

        </div>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Role);