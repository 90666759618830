import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './app/store/store';
import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import Buffer from 'buffer'
import { Box, CircularProgress } from '@mui/material';
import { centered_flex_box } from './app/components/Styles';


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
