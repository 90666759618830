import React from "react";
import "./App.css";
import Router from "./Router";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { Box, createTheme, IconButton, ThemeProvider } from "@mui/material";
import BackToTop from 'react-back-to-top';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BottomBar from "./app/components/BottomBar";
import ScrollToTop from "./app/components/RouteToTop";
import ReactGA from 'react-ga4';
import { centered_flex_box } from "./app/components/Styles";
import { ArrowBack, Home } from "@mui/icons-material";
import NavBar from "./app/components/NavBar";
import InactivityTimeoutWrapper from "./TimeoutWrapper";


ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
      secondary: "#E1000F"
    },
    secondary: {
      main: '#ffffff'
    },
    white: {
      main: "#ffffff"
    },
  }
});

function App() {
  console.log(window.location)

  return (
    <ThemeProvider theme={theme}>
      <div className="App position-relative">
        <BrowserRouter>
          {window.location.pathname !== "/HenkelCareers" && <InactivityTimeoutWrapper>
            <Router />
          </InactivityTimeoutWrapper>}
          {window.location.pathname === "/HenkelCareers" && <Router />}
          <NavBar />
          <ScrollToTop />
          <BackToTop
            mainStyle={{
              "border-radius": 30,
              "background-color": "var(--secColor)"
            }}
            children={<KeyboardArrowUpIcon sx={{ color: "var(--appBg)" }} />}
            isPercent={false}
            offsetTop={0}
            step={50} />
          <BottomBar />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
