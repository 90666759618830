

import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Drawer, Grid, IconButton, Link, Tooltip } from "@mui/material";
import { centered_flex_box, left_flex_box, right_flex_box } from "./Styles";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
} from "react-device-detect";
import Logo from "../components/Henkel_Logo_White.png"
import { Image } from "antd";
import { ArrowBack, Home } from "@mui/icons-material";

function Navbar({ }) {
    const navigate = useNavigate();

    return (
        <Box sx={{ ...centered_flex_box }} className="position-absolute top-0 start-0">
            {window.location.pathname !== "/" && window.location.pathname !== "/HenkelCareers" && <IconButton onClick={() => navigate(-1)}><ArrowBack sx={{ color: "var(--secColor)", width: "4vw", height: "4vw" }} /></IconButton>}
            {window.location.pathname !== "/" && window.location.pathname !== "/HenkelCareers" && !isMobile && <IconButton onClick={() => navigate("/")}><Home sx={{ color: "var(--secColor)", width: "4vw", height: "4vw" }} /></IconButton>}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    auth: state?.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
