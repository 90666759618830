import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { MainInput, centered_flex_box, main_button } from '../../app/components/Styles';
import { doc, getDoc, collection } from "firebase/firestore";
import { db } from "../../app/config";
import { useEffect } from 'react';
import { useState } from 'react';

export const Internal = ({ }) => {

    const navigate = useNavigate()
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false);
    const [major, setMajor] = useState([]);

    useEffect(() => {
        getMajor()
    }, [])

    const getMajor = async () => {
        setIsLoading(true)
        const docRef = doc(db, "major", id);
        const docSnap = await getDoc(docRef);
        setMajor({id: docSnap.id, ...docSnap.data()})
        setIsLoading(false)
    }

    return (
        <div>
            <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100vw", padding: 2, marginBottom: 5 }}>
                <Typography fontWeight="bold" variant="h2" sx={{ color: "var(--secColor)" }}>{major?.name}</Typography>
            </Box>

            <Grid container spacing={3} sx={{ ...centered_flex_box, height: "70vh", width: "100vw" }}>
                {major?.has_graduate && <Grid item xs={6} sx={{ ...centered_flex_box, flexDirection: "column", justifyContent: "start" }}>
                    <Button onClick={() => navigate(`/Graduate/${id}`)} key={1} sx={{ ...main_button, width: "30vw", height: "15vw", fontSize: 28 }}>
                        Graduate
                    </Button>
                </Grid>}
                {major?.has_undergraduate && <Grid item xs={6} sx={{ ...centered_flex_box, flexDirection: "column", justifyContent: "start" }}>
                    <Button onClick={() => navigate(`/Undergraduate/${id}`)} key={1} sx={{ ...main_button, width: "30vw", height: "15vw", fontSize: 28 }}>
                        Undergraduate
                    </Button>
                </Grid>}
                {isLoading && <Box sx={centered_flex_box}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>}
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Internal);