import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { MainInput, centered_flex_box, main_button } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import { useEffect } from 'react';
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../app/config"

export const AllMajors = ({ }) => {

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [majors, setMajors] = useState([]);

    useEffect(() => {
        getMajors()
    }, [])

    const getMajors = async () => {
        setIsLoading(true)
        const q = query(collection(db, "major"), where("visible", "==", true));
        const querySnapshot = await getDocs(q);
        const data = []
        querySnapshot.forEach((doc) => {
            data.push({id: doc.id, ...doc.data()});
        });
        setMajors(data);
        setIsLoading(false)
    }

    return (
        <div>
            <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100vw", padding: 2 }}>
                <Typography fontWeight="bold" variant="h2" sx={{ color: "var(--secColor)" }}>Major</Typography>
            </Box>

            <Grid container spacing={3} sx={{ ...centered_flex_box, height: "70vh" }}>
                {majors.map((major, index) => (
                    <Grid item xs={12} sx={centered_flex_box}>
                        <Button onClick={() => navigate(`/Major/${major.id}`)} key={index} sx={{ ...main_button, width: "30vw", fontSize: 20 }}>
                            {major.name}
                        </Button>
                    </Grid>
                ))}
                {isLoading && <Box sx={centered_flex_box}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>}
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AllMajors);