import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { MainInput, centered_flex_box, main_button, sec_button } from '../app/components/Styles';
import { isDesktop, isIPad13, isMobile, isTablet } from 'react-device-detect';
import { Image, QRCode } from 'antd';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { doc, getDoc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../app/config";
import { People } from '@mui/icons-material';
import { useState } from 'react';

export const ConnectHenkel = ({ }) => {

    return (
        <div>
            <Box>
                <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100vw", padding: 2, marginBottom: 1 }}>
                    <Typography textAlign="center" fontWeight="bold" variant="h3" sx={{ color: "var(--secColor)" }}>Connect with Henkel</Typography>
                </Box>
                <Grid container spacing={2} sx={{ padding: 5 }}>
                    <Grid item sx={{ ...centered_flex_box }} xs={4}>
                        <QRCode
                            value='https://www.linkedin.com/company/henkel/'
                            icon="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png"
                        />
                    </Grid>
                    <Grid item sx={{ ...centered_flex_box }} xs={4}>
                        <QRCode
                            value='https://www.instagram.com/henkel/'
                            icon="https://cdn.pixabay.com/photo/2020/11/15/06/18/instagram-logo-5744708_640.png"
                        />
                    </Grid>
                    <Grid item sx={{ ...centered_flex_box }} xs={4}>
                        <QRCode
                            value='https://www.henkel.com/'
                            icon="https://integra-apps.s3.eu-west-3.amazonaws.com/henkel-career-fair/logo192.png"
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectHenkel);