import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { MainInput, centered_flex_box, main_button } from '../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { Image } from 'antd';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { doc, getDoc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../app/config";

export const Scan = ({ }) => {

    const navigate = useNavigate()
    const { id, major } = useParams()

    useEffect(() => {
        if(id){
            const docRef = doc(db, "role", id);
            const majorRef = doc(db, "major", major);
            console.log(majorRef)
            getDoc(docRef).then(async (docSnap) => {
                if (docSnap.exists()) {
                    const majorSnap = await getDoc(majorRef)
                    const viewRef = await addDoc(collection(db, "views"), {
                        search_term: docSnap.data().title,
                        graduate_status: docSnap.data().graduate_status,
                        scanned_at: serverTimestamp(),
                        major: majorSnap.exists() ? majorSnap.data().name : major
                    });

                    setTimeout(() => {
                        window.location.href = docSnap.data().job_opening_link
                    }, 500);
                } else {
                    console.log("No such role!");
                }
            });
        }
    }, [id]);

    return (
        <div>.
            <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100vw", height: "80vh", padding: 2 }}>
                <Image height={100} width={178.65} preview={false} src={"https://integra-apps.s3.eu-west-3.amazonaws.com/henkel-career-fair/Henkel_Logo.png"} />
                <Typography fontWeight="bold" variant="h3" sx={{ color: "var(--secColor)" }}>Redirecting</Typography>
                <Box sx={centered_flex_box}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>
            </Box>
        </div>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Scan);