import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { MainInput, centered_flex_box, main_button, sec_button } from '../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { Image } from 'antd';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { doc, getDoc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../app/config";
import { People } from '@mui/icons-material';

export const Redirect = ({ }) => {

    const navigate = useNavigate()
    const [isRedirecting, setIsRedirecting] = React.useState(false);

    // useEffect(() => {
    //     logView();
    // }, []);

    const logView = async (link, search_term) => {
        setIsRedirecting(true);

        const viewRef = await addDoc(collection(db, "views"), {
            search_term,
            scanned_at: serverTimestamp(),
        });

        setTimeout(() => {
            window.location.href = link 
        }, 500);
    }

    return (
        <div>
            {!isRedirecting && <Box>
                <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100vw", padding: 2, marginBottom: 1 }}>
                    <Typography textAlign="center" fontWeight="bold" variant="h3" sx={{ color: "var(--secColor)" }}>Openings</Typography>
                </Box>
                <Grid container spacing={0} sx={{ ...centered_flex_box, height: "50vh", width: "100vw" }}>
                    <Grid item xs={12} sx={centered_flex_box}>
                        <Button onClick={() => logView("https://www.henkel.com/careers/jobs-and-application#selectFilterByParameter=Locations_279384=Africa%20%26%20Middle%20East&Africa___Middle_East_878190=Egypt&startIndex=0&loadCount=10&", "Henkel Careers")} sx={{ ...main_button, width: "70vw", fontSize: 20 }}>
                            Graduate
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={centered_flex_box}>
                        <Button onClick={() => navigate(`/Internship`)} sx={{ ...main_button, width: "70vw", fontSize: 20 }}>
                            Undergraduate
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={centered_flex_box}>
                        <Button onClick={() => logView("https://www.henkel.com/", "Henkel Website")} sx={{ ...sec_button, width: "70vw", fontSize: 20 }}>
                            Henkel Website
                        </Button>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item sx={{ ...centered_flex_box }} xs={4}>
                            <IconButton onClick={() => logView("https://www.linkedin.com/company/henkel/", "Linkedin")}><Avatar variant="square" src='https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png' /></IconButton>
                        </Grid>
                        <Grid item sx={{ ...centered_flex_box }} xs={4}>
                            <IconButton onClick={() => logView("https://www.instagram.com/henkel/", "Instagram")}><Avatar variant="square" src='https://cdn.pixabay.com/photo/2020/11/15/06/18/instagram-logo-5744708_640.png' /></IconButton>
                        </Grid>
                        <Grid item sx={{ ...centered_flex_box }} xs={4}>
                            <IconButton sx={{color: "var(--secColor)"}} onClick={() => navigate("/Team")}><People color='var(--secColor)'/></IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>}
            {isRedirecting && <Box sx={{ ...centered_flex_box, flexDirection: "column", width: "100vw", height: "80vh", padding: 2 }}>
                <Image height={100} width={178.65} preview={false} src={"https://integra-apps.s3.eu-west-3.amazonaws.com/henkel-career-fair/Henkel_Logo.png"} />
                <Typography fontWeight="bold" variant="h3" sx={{ color: "var(--secColor)" }}>Redirecting...</Typography>
                <Box sx={centered_flex_box}>
                    <CircularProgress sx={{ color: "var(--secColor)" }} />
                </Box>
            </Box>}
        </div>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Redirect);